import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isBrowser, isDesktop } from "react-device-detect";
import { REACT_APP_LIFF_ID } from '../env';


export function DeviceDetectPage({ children }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (isDesktop || isBrowser) {
            navigate('/qrcode');
        }
        else {
            window.location.href = `https://line.me/R/app/${REACT_APP_LIFF_ID}`;
        }
    }, []);
 
    return <div />;
}