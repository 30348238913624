

// import trackingHandler from "./utils/tracking-handler";
import { QRCodePage } from './pages/QRCodePage';
import LiffPage from './pages/LiffPage';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import './App.sass';
import './App.scss';
import { DeviceDetectPage } from './pages/DeviceDetectPage';

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<DeviceDetectPage />} />
          <Route path="/qrcode" element={<QRCodePage />} />
          <Route path="/liff" element={<LiffPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App;
