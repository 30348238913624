import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { REACT_APP_API_URL, REACT_APP_LIFF_ID, REACT_APP_GA_ID } from '../env';
import liff from "@line/liff";
import axios from 'axios';
import trackingHandler from '../utils/tracking-handler';

export default function LiffPage() {
  const [userId, setUserId] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [jwt, setJwt] = useState('');

  useEffect(() => {
    ReactGA.initialize(REACT_APP_GA_ID);
    const defaultliffId = REACT_APP_LIFF_ID;
    const liffInit = async () => {
      try {
        await liff
          .init({ liffId: defaultliffId })
          .then(() => {
            if (liff.isLoggedIn()) {
              liff
                .getProfile()
                .then((profile) => {
                  setUserId(profile.userId);
              })
              const accessToken = liff.getAccessToken();
              setAccessToken(accessToken);
            } else {
              liff.login({ redirectUri: window.location.href });
            }
          })
          .catch((err) => {

          })
      } catch (err) {
      }
    }
    liffInit();
  }, []);

  useEffect(() => {
    if (userId === '' || accessToken === '') {
      return
    }
    console.log(userId, accessToken)
    axios.post(REACT_APP_API_URL + '/api/login', {
      line_uid: userId,
      access_token: accessToken,
      liff_id: REACT_APP_LIFF_ID,
    })
      .then((res) => {
        setJwt(res.data.jwt);
      })
      .catch((err) => {

      })
  }, [userId, accessToken]);

  useEffect(() => {
    if (jwt === '') {
      return
    }
    const headers = {
      'Authorization': 'Bearer ' + jwt
    }
    axios.post(REACT_APP_API_URL + '/api/log/page', {
      path: 'index',
      referrer: document.referrer,
      ts: Math.floor(Date.now() / 1000),
    }, { headers: headers })
    .then((res) => {
      trackingHandler.trackingEvent('pv_index', 'page_view', 'index');
      window.location.href = 'https://urldefense.com/v3/__https:/inline.app/booking/-NRWNKkgcCLzpJnrwoA2:inline-live-3/-NRWNKwzRNJbwHzOp_7F__;!!Lt0KOR8!UYl3cnewon-syy0sbSlItae5JYCyU9rmuSsTXRwmqX2qQoVjgiug9ujd9NzlybuZwdiaAXVJI90-I1oishkqNg$';
    })
    .catch((err) => {
      window.location.href = 'https://urldefense.com/v3/__https:/inline.app/booking/-NRWNKkgcCLzpJnrwoA2:inline-live-3/-NRWNKwzRNJbwHzOp_7F__;!!Lt0KOR8!UYl3cnewon-syy0sbSlItae5JYCyU9rmuSsTXRwmqX2qQoVjgiug9ujd9NzlybuZwdiaAXVJI90-I1oishkqNg$';
    })

  }, [jwt]);

  return (
    <div />
  )
}