
import styles from './QRCodePage.module.scss';

export function QRCodePage() {


    return (
        <div className={styles.container}>
            <img alt="qrcde" src="/Loewe-QRcode.png" />
        </div>
    )
}