import ReactGA from "react-ga4";

const trackingHandler = {
    sendPageView: async (location) => {
        // Store in our server after login.
        const params = {
            action: "page_view",
            category: location.pathname,
        };

        // GA
        ReactGA.send({ hitType: "liff_page_view", ...params });
    },
    trackingEvent: async (event_name, category = '', action = '') => {
        try {
            // GA event
            ReactGA.event(event_name, {
                category: category,
                action: action,
            });
        } catch (error) {
            console.error("Tracking event error:", error);
        }
    },
};

export default trackingHandler;
